<template>
  <div>
    <!-- <ayl-berad-nav :nav="nav"></ayl-berad-nav> -->
    <div class="content-box mt20px">
      <div class="content-main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          :label-position="$config.labelPosition"
        >
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
              <el-form-item label="标段" prop="bidId">
                <el-cascader
                  placeholder="请选择"
                  v-model="form.bidId"
                  :options="bidOptions"
                  @change="handleBidChage"
                  clearable
                  :show-all-levels="false"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="form-label-base">人员预警设置</div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
              <el-form-item label="人员迟到预警时间(min)" prop="personLate">
                <el-input
                  v-model.number="form.personLate"
                  type="number"
                  placeholder="请输入"
                  maxlength="25"
                  :min="0"
                ></el-input>
                <span class="color-class">注：距离应到岗N分钟时预警</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
              <el-form-item label="人员离岗预警时间(min)" prop="personOff">
                <el-input
                  v-model.number="form.personOff"
                  type="number"
                  placeholder="请输入"
                  maxlength="25"
                  :min="0"
                ></el-input>
                <span class="color-class">注：离岗持续N分钟时预警</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
              <el-form-item label="人员静止预警时间(min)" prop="personRest">
                <el-input
                  v-model.number="form.personRest"
                  type="number"
                  placeholder="请输入"
                  maxlength="25"
                  :min="0"
                ></el-input>
                <span class="color-class">注：班次内静止总时长大于N分钟时预警</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
              <el-form-item label="人员轨迹一致时间(min)" prop="personTrackConsistent">
                <el-input
                  v-model.number="form.personTrackConsistent"
                  type="number"
                  placeholder="请输入"
                  maxlength="25"
                  :min="0"
                ></el-input>
                <span class="color-class">注：班次内轨迹一致时长大于N分钟时预警</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
              <el-form-item label="人员作业里程不足百分比(%)" prop="personInsufficientWork">
                <el-input
                  v-model.number="form.personInsufficientWork"
                  type="number"
                  placeholder="请输入"
                  maxlength="25"
                  :min="0"
                ></el-input>
                <span class="color-class">注：班次内人员作业里程百分比小于N%时预警</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="form-label-base">车辆预警设置</div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
              <el-form-item label="机扫晚点预警时间(min)" prop="carLateScanning">
                <el-input
                  v-model.number="form.carLateScanning"
                  type="number"
                  placeholder="请输入"
                  maxlength="25"
                  :min="0"
                ></el-input>
                <span class="color-class">注：距离应到岗N分钟时预警</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
              <el-form-item label="机洗晚点预警时间(min)" prop="carLateWashing">
                <el-input
                  v-model.number="form.carLateWashing"
                  type="number"
                  placeholder="请输入"
                  maxlength="25"
                  :min="0"
                ></el-input>
                <span class="color-class">注：距离应到岗N分钟时预警</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="tac" style="margin-top: 40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关 闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import addForDialog from "@/mixins/add-for-dialog";
export default {
  mixins: [addForDialog],
  props: {
    dataFromDialog: {
      //从弹窗获得传来的参数等数据，！！！！名称固定！！！！
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    const vm = this;
    return {
      nav: [{ name: "编辑作业规则预警" }],
      // 标段下拉
      bidOptions: [],
      form: {
        bidId: null, //标段
        personLate: null, //人员迟到预警时间
        personOff: null, //人员离岗预警时间
        personRest: null, //人员静止预警时间
        personTrackConsistent: null, //人员轨迹一致时间
        personInsufficientWork: null, //人员作业里程不足百分比
        carLateScanning: null, //机扫晚点预警时间
        carLateWashing: null, //机洗晚点预警时间
      },
      rules: {
        bidId: {
          required: true,
          message: "请选择",
          trigger: "change",
        },
        personLate: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
        personOff: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
        personRest: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
        personTrackConsistent: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
        personInsufficientWork: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
        carLateScanning: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
        carLateWashing: {
          required: true,
          message: "必填字段不能为空",
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    // 提交表单
    async submit() {
      let self = this;
      //提交form数据
      console.log(_fixData(self.form));
      await this.$api_hw
        .operationSafetyManagement_jobPreWarningBatchUpdate({
          info: _fixData(self.form),
        })
        .then((res) => {
          BUS.$emit(BUSEVENT.REFRESH_OPERATIONAL_EARLY_WARNING_MANAGEMENT);
        });
      //修剪form的数据
      function _fixData(d) {
        for (let key in d) {
          switch (key) {
            case "gmtCreate":
            case "gmtModified":
              delete d[key];
              break;
          }
        }
        //补字段
        if (self.mapData) {
          if (self.mapData.lnglat)
            d["ecoCoordinateScope"] = JSON.stringify(self.mapData.lnglat);
          else d["ecoCoordinateScope"] = "";
          d["isDrawn"] = self.mapData.lnglat ? 1 : 0;
        }
        self.log(d);
        return d;
      }
    },
  },
  async mounted() {
    // 获取标段
    this.bidOptions = await this.$api_hw.common_getBidNameAndIdList({});
    this.form = await this.$api_hw.operationSafetyManagement_jobPreWarningGet({
      id: this.dataFromDialog.id,
    });
    console.log(this.form);
  },
};
</script>

<style lang='sass' scoped>
.content-box .content-main
  padding: 0 20px 20px
.content-box 
  padding: 0
/deep/ el-form
  display: flex;
  flex-wrap: wrap;
/deep/ .el-form-item
  display: flex;
  width: 100%;
/deep/ .el-date-editor.el-input,
/deep/ .el-range-editor--mini.el-input__inner,
/deep/ .el-cascader--mini,
/deep/ .el-select
  width: 100%;
/deep/ .el-form-item--mini .el-form-item__content
  flex: 1;
/deep/ .el-form-item--mini .el-form-item__label 
  text-align: right;
  width: 200px;

/deep/ .el-form-item .el-input
  width: 30%;

.color-class
  margin-left: 20px
</style>